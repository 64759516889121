var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "userbox" }, [
    _c("div", { staticClass: "userInfo" }, [
      _c("div", { staticClass: "info" }, [
        _c("p", [_vm._v("员工姓名")]),
        _c("p", [_vm._v(_vm._s(_vm.userInfo.staffName || "-"))]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("p", [_vm._v("工号")]),
        _c("p", [_vm._v(_vm._s(_vm.userInfo.staffNum || "-"))]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("p", [_vm._v("部门")]),
        _c("p", [_vm._v(_vm._s(_vm.userInfo.orgName || "-"))]),
      ]),
      _c("div", [_vm._t("content")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }